const GetCategoriesForUserUrl = 'https://u2qmc67vev6o4axhidsefqhvh40wlhtt.lambda-url.us-east-1.on.aws/';
const GetTasksForUserUrl = 'https://zdupvfpokqeeg56cndq4tnjvo40mioor.lambda-url.us-east-1.on.aws/';
const CreateTaskForUserUrl = 'https://pwqr7fg6di432bjm4crwy2avoy0snnnb.lambda-url.us-east-1.on.aws/';
const UpdateTaskOrderUrl = 'https://drsy7ipolclp3uv4xmifnjfwxy0tfgua.lambda-url.us-east-1.on.aws/';
const UpdateTaskUrl = 'https://ixvqczvmwwbcxnatb6n5e24epq0tzwun.lambda-url.us-east-1.on.aws/';
const CreateCategoryUrl = 'https://4ni3psfsemi3phqcpzbn4qkdlm0vudsj.lambda-url.us-east-1.on.aws/';
const DeleteTaskUrl = 'https://4plzewlxzuvasvzin7qcc5jnma0qxjxr.lambda-url.us-east-1.on.aws/';
const RenameCategoryUrl = 'https://iywigiuyc3uqgt6j3n3odqq25i0svycy.lambda-url.us-east-1.on.aws/';
const DeleteCategoryUrl = 'https://waob7gw4qecftrwolcitgp7yfu0vagdx.lambda-url.us-east-1.on.aws/';
const LoginUrl = 'https://hpswvvu5gajldrnnf5v45eahke0vvike.lambda-url.us-east-1.on.aws/';
const GetUsersUrl = 'https://sbvzgm7nxynsah5u3pmk6scl6i0aawaq.lambda-url.us-east-1.on.aws/';
const DeleteUserUrl = 'https://5wkkdku5wwhrm7z7vabnzya6ye0qbnav.lambda-url.us-east-1.on.aws/';
const UpdateUserUrl = 'https://ef2imksmeyrdy2w3xxorx5rr4u0brydk.lambda-url.us-east-1.on.aws/';
const SetPasswordUrl = 'https://27kzt5azg6wt4uwpzdbtqvwoqq0jpymg.lambda-url.us-east-1.on.aws/';
const CreateUserUrl = 'https://4uyq73kfjsrnpafk2fys56p5au0uplfi.lambda-url.us-east-1.on.aws/';

export async function createCategory(sessionId, categoryName) {
  const response = await fetch(CreateCategoryUrl, {
    method: 'POST',
    body: JSON.stringify({
      "session_id": sessionId,
      "name": categoryName
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const resp = await response.json();
  return resp;
}

export async function getCategoriesForUser(sessionId, logout) {
  const response = await fetch(GetCategoriesForUserUrl, {
    method: 'POST',
    body: JSON.stringify({
      "session_id": sessionId
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const categories = response.status === 200 ? await response.json() : '';

  if (response.status === 401)
    logout();
  
  return categories;
}

export async function getTasksForUser(sessionId, categoryId, logout) {
  const response = await fetch(GetTasksForUserUrl, {
    method: 'POST',
    body: JSON.stringify({
      "session_id": sessionId,
      "category_id": categoryId
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const tasks = response.status === 200 ? await response.json() : '';
  
  if (response.status === 401)
    logout();

  return tasks;
}

export async function createTaskForUser(sessionId, dateCreated, categoryId, description, logout) {
  const response = await fetch(CreateTaskForUserUrl, {
    method: 'POST',
    body: JSON.stringify({
      "session_id": sessionId,
      "category_id": categoryId,
      "date_created": dateCreated,
      "description": description,
      "priority": false
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const task = response.status === 200 ? await response.json() : '';

  if (response.status === 401)
    logout();
  
  return task;
}

export async function updateTaskOrder(sessionId, tasks) {
  const response = await fetch(UpdateTaskOrderUrl, {
    method: 'POST',
    body: JSON.stringify({
      session_id: sessionId,
      tasks: tasks
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const resp = await response.json();
  return resp;
}

export async function updateTask(task, logout) {
  const response = await fetch(UpdateTaskUrl, {
    method: 'POST',
    body: JSON.stringify(task),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const resp = response.status === 200 ? await response.json() : '';

  if (response.status === 401)
    logout();

  return resp;
}

export async function deleteTasks(sessionId, tasks, categoryId, logout) {
  const response = await fetch(DeleteTaskUrl, {
    method: 'POST',
    body: JSON.stringify({
      "session_id": sessionId,
      "task_ids": tasks.map(t => t.task_id),
      "category_id": categoryId
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (response.status === 401)
    logout();

  return response;
}

export async function renameCategory(categoryId, newName) {
  const response = await fetch(RenameCategoryUrl, {
    method: 'POST',
    body: JSON.stringify({
      "category_id": categoryId,
      "name": newName
    }),
    header: {
      'Content-Type': 'application/json'
    }
  });

  return response;
}

export async function deleteCategory(categoryId) {
  const response = await fetch(DeleteCategoryUrl, {
    method: 'POST',
    body: JSON.stringify({
      "category_id": categoryId
    }),
    header: {
      'Content-Type': 'application/json'
    }
  });

  return response;
}

export async function login(userName, password) {
  const response = await fetch(LoginUrl, {
    method: 'POST',
    body: JSON.stringify({
      "user_name": userName,
      "password": password
    }),
    header: {
      'Content-Type': 'application/json'
    }
  });
  
  let body = response.status === 200 ? await response.json() : '';

  return {
    status: response.status,
    body: body
  }
}

export async function getUsers(sessionId) {
  const response = await fetch(GetUsersUrl, {
    method: 'POST',
    body: JSON.stringify({
      "session_id": sessionId
    }),
    header: {
      'Content-Type': 'application/json'
    }
  });

  let body = response.status === 200 ? await response.json() : '';

  return {
    status: response.status,
    body: body
  }
}

export async function deleteUser(sessionId, userName) {
  const response = await fetch(DeleteUserUrl, {
    method: 'POST',
    body: JSON.stringify({
      "session_id": sessionId,
      "user_name": userName
    }),
    header: {
      'Content-Type': 'application/json'
    }
  });

  return response.status;
}

export async function updateUser(sessionId, userName, fullName, isAdmin) {
  const response = await fetch(UpdateUserUrl, {
    method: 'POST',
    body: JSON.stringify({
      "session_id": sessionId,
      "user_name": userName,
      "full_name": fullName,
      "is_admin": isAdmin
    }),
    header: {
      'Content-Type': 'application/json'
    }
  });

  return response.status;
}

export async function setPassword(sessionId, userName, password) {
  const response = await fetch(SetPasswordUrl, {
    method: 'POST',
    body: JSON.stringify({
      "session_id": sessionId,
      "user_name": userName,
      "password": password
    }),
    header: {
      'Content-Type': 'application/json'
    }
  });

  return response.status;
}

export async function createUser(sessionId, userName, fullName, password) {
  const response = await fetch(CreateUserUrl, {
    method: 'POST',
    body: JSON.stringify({
      "session_id": sessionId,
      "user_name": userName,
      "password": password,
      "full_name": fullName
    }),
    header: {
      'Content-Type': 'application/json'
    }
  });

  return response.status;
}

export function formatDate(d) {
  return ((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"/"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+"/"+d.getFullYear().toString().substring(2);
}