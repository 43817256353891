import React, { useMemo, useRef, useState, useCallback, useEffect, useImperativeHandle, forwardRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './TaskmanGridStyles.css';

import CheckboxRenderer from "./CheckboxRenderer.js";
import DeleteIconRenderer from "./DeleteIconRenderer.js";

import { getTasksForUser, updateTaskOrder, updateTask, deleteTasks } from './DataInterface.js';
import { useNavigate } from 'react-router-dom';

const TaskComponent = forwardRef((props, ref) => {
    const audio = new Audio('qclick.wav');

    const [tasks, setTasks] = useState([]);
    
    const [showInvalidValue, setShowInvalidValue] = useState(false);

    const [currentCategoryId, setCurrentCategoryId] = useState({});

    const [proposedDeleteTasks, setProposedDeleteTasks] = useState([]);
    const [showDeleteTask, setShowDeleteTask] = useState(false);
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
        refresh(categoryId) {
            loadData(categoryId);
        }
    }), []);

    useEffect(() => {
        if (props.export.count > 0) {
            exportTasks(props.export.categoryName, props.export.print);
        }
    },[props.export]);

    useEffect(() => {
        if (props.deleteSelected.count > 0) {
            const tasksToDelete = tasks.filter(t => t.deleteSelected);
            setProposedDeleteTasks(tasksToDelete);
        }
    }, [props.deleteSelected]);

    function exportTasks(categoryName, print) {
        let line = [];
        line.push('<html>');
        line.push('<head><title>');
        line.push(categoryName);
        line.push(' Tasks</title></head>');
        line.push('<body>');
        line.push('<h2>');
        line.push(categoryName);
        line.push(' Tasks</h2>');
        line.push('<style>');
        line.push('#printout { font-family: Arial, Helvetica, sans-serif; border-collapse: collapse; width: 100%; }');
        line.push('#printout td, #printout th { border: 1px solid #ddd; padding: 8px; }');
        line.push('#printout th { padding-top: 12px; padding-bottom: 12px; text-align: left; background-color: #aaa; color: white; }');
        line.push('</style>');
        line.push("<table id='printout''>");
        line.push("<tbody'>");
        line.push("<thead><tr><td>#</td><td>Date</td><td>Task</td></tr></thead>");
        tasks.map(t => {
            line.push("<tr>");

            line.push("<td>");
            line.push(t.number);
            line.push("</td>");

            line.push("<td>");
            line.push(t.dateCreated);
            line.push("</td>");

            line.push("<td>");
            line.push(t.task);
            line.push("</td>");

            line.push("</tr>")
        });

        line.push("</tbody>");
        line.push("</table>");
        line.push("</body>");
        line.push("</html>");

        const type = "text/html";
        const { ClipboardItem } = window;
        const blob = new Blob([line.join('')], { type });
        const data = [new ClipboardItem({ [type]: blob})];

        if (!print) {
            navigator.clipboard.write(data).then(
                (e) => {
                },
                (err) => {
                    alert("Error: " + err);
                }
            )

            audio.play();
        } else {
            const win = window.open();
            win.document.body.innerHTML = line.join('');
        }

    }

    function loadData(categoryId) {
        if (!props.sessionId || props.sessionId === '')
            return;

        if (!categoryId && !currentCategoryId)
            return;

        getTasksForUser(props.sessionId, categoryId ?? currentCategoryId, function() { navigate('/'); }).then(response =>
            {
                let tasks = [];
                response.map(t => {
                    tasks.push({
                        done: t.completed,
                        number: t.order,
                        dateCreated: t.date_created,
                        task: t.description,
                        task_id: t.task_id,
                        priority: t.priority
                    });
                });

                tasks.sort(function (a, b) {
                    return a.number - b.number;
                });

                setTasks(tasks);

                props.tasksPopulated(tasks.length);
            }
        );
    }

    function resetTaskOrder(tasks) {
        updateTaskOrder(props.sessionId, tasks);
    }

    function reorderTasks(oldPosition, newPosition) {
        if (oldPosition == newPosition)
            return;

        let tasksCopy = gridRef.current.tasks.map((x) => x);
        let task = tasksCopy[oldPosition];                
        tasksCopy.splice(oldPosition, 1);
        if (newPosition >= 0)
            tasksCopy.splice(newPosition, 0, task);
        else
            tasksCopy.push(task);

        for(var i = 0; i < tasksCopy.length; i++)
            tasksCopy[i].number = i + 1;

        setTasks(tasksCopy);
        resetTaskOrder(tasksCopy);
    }

    function sendUpdatedTask(task) {
        let updatedTask = {
            task_id: task.task_id,
            completed: task.done,
            session_id: props.sessionId,
            description: task.task,
            date_created: task.dateCreated,
            priority: task.priority
        }

        updateTask(updatedTask, () => navigate('/'));
    }

    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridRef = useRef(); 

    useEffect(() => {
        setCurrentCategoryId(props.categoryId);
    }, [props.categoryId]);

    useEffect(() => {
        loadData();
    }, [currentCategoryId]);

    useEffect(() => {
        if (proposedDeleteTasks.length === 0)
            setShowDeleteTask(false);
        else
            setShowDeleteTask(true);
    }, [proposedDeleteTasks]);

    useEffect(() => {
        gridRef.current.tasks = tasks;
    });

    const [columnDefs] = useState([
        {
            colId: 'controls',
            cellRenderer: 'deleteIconRenderer',
            minWidth: 10,
            maxWidth: 100,
            rowDrag: true,
            suppressMovable: true
        },
        {
            field: 'done',
            cellRenderer: 'checkboxRenderer',
            minWidth: 10,
            maxWidth: 73,
            suppressMovable: true
        },
        {
            field: 'priority',
            headerName: '!',
            cellRenderer: 'checkboxRenderer',
            minWidth: 10,
            maxWidth: 50,
            suppressMovable: true
        },
        {
            field: 'number',
            headerName: '#',
            minWidth: 20,
            maxWidth: 60,
            suppressMovable: true
        },
        {
            headerName: 'Date',
            field: 'dateCreated',
            minWidth: 10,
            maxWidth: 100,
            suppressMovable: true
        },
        {
            field: 'task',
            flex: 10,
            wrapText: true,
            autoHeight: true,
            suppressMovable: true
        }
    ]);

    const [rowRulesDef] = useState( () => ({
        "ag-row-done-style": params => params.api.getValue("done", params.node),
        "ag-row-priority-style": params => params.api.getValue("priority", params.node)
    }));

    const defaultColDef = useMemo( ()=> ({
        editable: true
    }));

    const components = useMemo(() => ({checkboxRenderer: CheckboxRenderer,
                                        deleteIconRenderer: DeleteIconRenderer}), []);

    const cellClickedListener = useCallback( event => {
        console.log('cellClicked', event);

        if (event.column.colId !== 'done' 
        && event.column.colId !== 'priority'
        && event.column.colId !== 'controls') {  

            let editingCells = event.api.getEditingCells();

            // we're already editing this, let the click happen
            if (editingCells.length > 0 &&
                editingCells[0].column.colId === event.column.colId &&
                editingCells[0].rowIndex === event.rowIndex)
                return;

            event.api.stopEditing();

            event.api.startEditingCell({
                rowIndex: event.rowIndex,
                colKey: event.column.colId
            });
        }
    }, []);

    const cellValueChangedListener = useCallback( event => {
        console.log('cellChanged', event);
        if (event.column.colId === 'number') {
            var value = parseInt(event.newValue);
            if (isNaN(value) || !Number.isInteger(value)) {
                setShowInvalidValue(true);
                
                setTimeout(() => {
                    event.api.stopEditing();
                    var data = event.data;
                    data.number = event.oldValue;

                    event.node.setData(data);
                    event.api.startEditingCell({
                        rowIndex: event.rowIndex,
                        colKey: event.column.colId
                    });
                });
            } else {
                let oldPosition = event.oldValue - 1;
                let newPosition = value - 1;

                reorderTasks(oldPosition, newPosition);
            }
        } else if (event.column.colId === 'done'
                || event.column.colId === 'priority'
                || event.column.colId === 'dateCreated'
                || event.column.colId === 'task') {
                    sendUpdatedTask(event.data);
                }
    }, []);

    const rowDragEndListener = useCallback( event => {
        console.log('rowDragEnd', event);
        let oldPosition = event.node.data.number - 1;
        let newPosition = event.overNode.data.number - 1;

        reorderTasks(oldPosition, newPosition);
    }, []);

    const cellKeyDownListener = useCallback( event => {
        console.log('cellKeyDown', event);

        switch(event.event.code) {
        case "ArrowDown":
            event.api.stopEditing();
            
            if (event.column.colId !== 'done' 
             && event.column.colId !== 'priority'
             && event.column.colId !== 'controls') {
                if (event.column.colId === 'number') {
                    var value = parseInt(event.node.data.number);
                    if (isNaN(value) || !Number.isInteger(value))
                        return;
                }

                console.log("start editing");

                event.api.startEditingCell({
                    rowIndex: event.rowIndex + 1,
                    colKey: event.column.colId
                });
            }
            break;
        case "ArrowUp":
            event.api.stopEditing();
            if (event.column.colId !== 'done'
             && event.column.colId !== 'priority'
             && event.column.colId !== 'controls') {
                if (event.column.colId === 'number') {
                    var value = parseInt(event.node.data.number);
                    if (isNaN(value) || !Number.isInteger(value))
                        return;
                }

                console.log("start editing");
                
                event.api.startEditingCell({
                    rowIndex: event.rowIndex - 1,
                    colKey: event.column.colId
                });
            }
            break;
        }
    }, []);

    function hideDeleteTaskModal() {
      setProposedDeleteTasks([]);
    }

    function confirmDeleteTask() {
        deleteTasks(props.sessionId, proposedDeleteTasks, currentCategoryId, () => navigate('/')).then(response =>
            {
                loadData(currentCategoryId);
            }
        );
        hideDeleteTaskModal();
    }

    return (
        <div className="ag-theme-alpine" style={gridStyle}>

            <AgGridReact
                ref={gridRef}

                rowData={tasks}

                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowClassRules={rowRulesDef}

                //singleClickEdit={true}
                animateRows={true} 
                rowSelection='multiple'
                rowDragManaged={true}
                suppressMoveWhenRowDragging={true}
                suppressCellFocus={true}
                suppressRowClickSelection={true}
                suppressRowVirtualisation={true}
                suppressClickEdit={true}
                onCellClicked={cellClickedListener} 
                onCellValueChanged={cellValueChangedListener}
                components={components}
                onCellKeyDown ={cellKeyDownListener}
                onRowDragEnd={rowDragEndListener}
                />

            <Modal show={showInvalidValue} onHide={() => setShowInvalidValue(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Invalid Value</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>This value must be a valid number</Form.Label>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowInvalidValue(false)}>OK</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteTask} onHide={() => hideDeleteTaskModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Are you sure you want to delete the selected {proposedDeleteTasks.length} task(s)? This cannot be undone.</Form.Label>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hideDeleteTaskModal()}>No</Button>
                    <Button variant="primary" onClick={() => confirmDeleteTask()}>Yes</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
})

export default TaskComponent;