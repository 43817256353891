import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Tabs, TabList, Tab } from 'react-tabs';
import { getCategoriesForUser, createCategory, renameCategory, deleteCategory } from './DataInterface.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { Navigate, useNavigate } from 'react-router-dom';

const CategoryComponent = (props) =>
{
    // last left off.. need to make category default when a new one is created
    const [categories, setCategories] = useState([]);
    const [currentCategoryId, setCurrentCategoryId] = useState();

    const [showNewCategory, setShowNewCategory] = useState(false);
    const [showRenameCategory, setShowRenameCategory] = useState(false);
    const [showDeleteCategory, setShowDeleteCategory] = useState(false);
    const [renameCategoryState, setRenameCategoryState] = useState({});
    const [deleteCategoryState, setDeleteCategoryState] = useState({});

    const [tabIndexFromCategoryId, setTabIndexFromCategoryId] = useState();
    const [tabIndex, setTabIndex] = useState(0);

    const gridStyle = useMemo(() => ({ width: '100%' }), []);
    const newCategoryNameRef = useRef();
    const renameCategoryNameRef = useRef();
    const navigate = useNavigate();

    useEffect(() =>
        {
            loadData();
        }, [props.sessionId]);

    useEffect(() =>
        {
            if (categories.length > 1) {
                for(let cat in categories) {
                    if (categories[cat].category_id === tabIndexFromCategoryId.selectedCategoryId) {
                        setTabIndex(parseInt(cat));
                        props.categoryName(categories[cat].name);
                        break;
                    }
                }

                setCurrentCategoryId(tabIndexFromCategoryId.selectedCategoryId);
            }
            
        }, [categories]);

    useEffect(() => {
        if (!tabIndexFromCategoryId)
            return;
        
        setCategories(tabIndexFromCategoryId.categories);
    }, [tabIndexFromCategoryId]);

    useEffect(() => {
        if (!currentCategoryId)
            return;

        props.categoryChanged(currentCategoryId);
    }, [currentCategoryId]);

    function loadData(selectedCategoryId) {
        if (!props.sessionId || props.sessionId === '')
            return;

        getCategoriesForUser(props.sessionId, () => navigate('/')).then(response =>
            {
                response.sort(function (a, b) {
                    let aName = a.name.toUpperCase();
                    let bName = b.name.toUpperCase();
                    if (aName < bName)
                        return -1;
                    if (aName > bName)
                        return 1;
                    return 0;
                });

                let addCategory = {
                    category_id: '+',
                    enabled: true,
                    name: '+',
                    session_id: props.sessionId
                };

                response.push(addCategory);
                //setCategories(response);

                if (selectedCategoryId) {
                    setTabIndexFromCategoryId({ categories: response, selectedCategoryId: selectedCategoryId });
                } else {
                    setTabIndexFromCategoryId({ categories: response, selectedCategoryId: response[0].category_id });
                }
            });
    }

    const selectionChanged = useCallback((index) => {
        handleSelectionChanged(index);
    });

    function handleSelectionChanged(index) {
        let categoryId = categories[index].category_id;
        
        setTabIndex(index);

        if (categoryId === '+') {
            setShowNewCategory(true);
            return;
        }
        
        props.categoryName(categories[index].name);
        setCurrentCategoryId(categories[index].category_id);
    }

    function createNewCategory() {
        let categoryName = newCategoryNameRef.current.value;
        setShowNewCategory(false);

        createCategory(props.sessionId, categoryName).then(response =>
            {
                loadData(response.category_id);
            });
    }

    function handleDeleteCategory() {
        let categoryId = deleteCategoryState.categoryId;
        setDeleteCategoryState({});

        deleteCategory(categoryId).then(response =>
            {
                loadData();
            });
    }

    function handleRenameCategory() {
        let newCategoryName = renameCategoryNameRef.current.value;
        let categoryId = renameCategoryState.categoryId;
        setRenameCategoryState({});

        renameCategory(categoryId, newCategoryName).then(response =>
            {
                loadData(categoryId);
            });
    }

    function hideNewCategoryModal() {
        setShowNewCategory(false);
    }

    function hideRenameCategoryModal() {
        setRenameCategoryState({});
    }

    function hideDeleteCategoryModal() {
        setDeleteCategoryState({});
    }

    function deleteCategoryClicked(e, data) {
        setDeleteCategoryState({
            categoryId: data.category_id
        });
    }

    function renameCategoryClicked(e, data) {
        setRenameCategoryState({
            categoryId: data.category_id,
            name: data.name
        });
    }

    useEffect(() =>
    {
        if (renameCategoryState.categoryId)
            setShowRenameCategory(true);
        else
            setShowRenameCategory(false);
    }, [renameCategoryState]);

    useEffect(() =>
    {
        if (deleteCategoryState.categoryId)
            setShowDeleteCategory(true);
        else
            setShowDeleteCategory(false);
    }, [deleteCategoryState]);

    return (
        <div className="ag-theme-alpine" style={gridStyle}>
            <Tabs selectedIndex={tabIndex}
                onSelect={(index) => selectionChanged(index)}
                focusTabOnClick={true}>
                <TabList>
                    {categories
                    .filter(function(category) { return category.enabled })
                    .map((category) => 
                        ( <Tab><div class={'disable-select'}><ContextMenuTrigger id={'cm-' + category.category_id}>{category.name}</ContextMenuTrigger></div></Tab> )
                    )}
                </TabList>
            </Tabs>

            {categories
                    .filter(function(category) { return category.enabled })
                    .map((category) => 
                        ( <ContextMenu id={'cm-' + category.category_id}><MenuItem onClick={renameCategoryClicked} data={category}>Rename</MenuItem><MenuItem onClick={deleteCategoryClicked} data={category}>Delete</MenuItem></ContextMenu> )
                    )}
            
            <Modal show={showNewCategory} onHide={() => hideNewCategoryModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>New Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="categoryForm.ControlInput1">
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control as="input" ref={newCategoryNameRef} autoFocus controlId="categoryForm.categoryName"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hideNewCategoryModal()}>Close</Button>
                    <Button variant="primary" onClick={() => createNewCategory()}>Create</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRenameCategory} onHide={() => hideRenameCategoryModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Rename Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="renameCategoryForm.ControlInput1">
                        <Form.Label>New Category Name</Form.Label>
                        <Form.Control as="input" defaultValue={renameCategoryState.name} ref={renameCategoryNameRef} autoFocus controlId="renameCategoryForm.categoryName"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hideRenameCategoryModal()}>Close</Button>
                    <Button variant="primary" onClick={() => handleRenameCategory()}>Rename</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteCategory} onHide={() => hideDeleteCategoryModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="renameCategoryForm.ControlInput1">
                        <Form.Label>Are you sure you want to delete this category? This will delete all associated tasks and cannot be undone.</Form.Label>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hideDeleteCategoryModal()}>No</Button>
                    <Button variant="primary" onClick={() => handleDeleteCategory()}>Yes</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CategoryComponent;