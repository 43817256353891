import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
import MainViewComponent from './MainViewComponent';
import { Routes, Route, useNavigate } from 'react-router-dom';
import LoginViewComponent from './LoginViewComponent';
import AdminViewComponent from './AdminViewComponent';

const App = () => {
  const [sessionId, setSessionId] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  let loginProps = {
    setSessionId: setSessionId,
    setIsAdmin: setIsAdmin
  }

  let mainProps = {
    sessionId: sessionId,
    isAdmin: isAdmin
  }

  useEffect(() => {
    if (sessionId !== '') {
      mainProps.sessionId = sessionId;
      mainProps.isAdmin = isAdmin;
      sessionStorage.setItem('taskmanSessionId', sessionId);
      sessionStorage.setItem('taskmanIsAdmin', isAdmin);
      navigate("/main");
    }
  }, [sessionId])

  useEffect(() => {
    if (sessionId === '') {
      const lsSessionId = sessionStorage.getItem('taskmanSessionId') || '';
      const lsIsAdmin = sessionStorage.getItem('taskmanIsAdmin') || false;

      if (lsSessionId !== '')
        setIsAdmin(lsIsAdmin);

      setSessionId(lsSessionId);
    }
  }, [])

  return (
      <Routes>
        <Route exact path="/" element={LoginViewComponent(loginProps)}/>
        <Route path="/main" element={MainViewComponent(mainProps)}/>
        <Route path="/admin" element={AdminViewComponent(mainProps)}/>
      </Routes>
  );
};

export default App;