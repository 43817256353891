import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
import CategoryComponent from './CategoryComponent';
import CreateTaskComponent from './CreateTaskComponent';
import TaskComponent from './TaskComponent';
import { createTaskForUser } from './DataInterface';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const MainViewComponent = (props) => {
    const [selectedCategoryId, setCategoryId] = useState('');
    const [taskCount, setTaskCount] = useState(0);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [sessionId, setSessionId] = useState();
    const [isAdmin, setIsAdmin] = useState();
    const [doExport, setDoExport] = useState({count: 0, categoryName: ''});
    const [doDeleteSelected, setDoDeleteSelected] = useState({count : 0});
    const [categoryName, setCategoryName] = useState('');
    
    const taskComponentRef = useRef();
    const navigate = useNavigate();
    
    const audio = new Audio('qclick.wav');
  
    function categoryChanged(categoryId) {
      setCategoryId(categoryId);
    }
  
    function createNewTask(taskDescription, dateCreated) {
      createTaskForUser(sessionId, dateCreated, selectedCategoryId, taskDescription, () => navigate('/')).then(response =>
        {
            refresh();
            audio.play();
        });     
    }

    function refresh() {
        taskComponentRef.current.refresh(selectedCategoryId);
    }
  
    function tasksPopulated(count) {
      setTaskCount(count);
    }

    useEffect(() => {
        setSessionId(props.sessionId);
    }, [props.sessionId]);

    useEffect(() => {
        setIsAdmin(props.isAdmin);
    }, [props.isAdmin]);
  
    function navAdmin() {
        navigate('/admin');
    }

    function logout() {
        localStorage.removeItem('taskmanSessionId');
        localStorage.removeItem('taskmanIsAdmin');
        setSessionId('');
        navigate('/');
    }

    return (
        <div style={containerStyle}>
            <Row>
                <Col xs={3}><h1>Task Manager</h1></Col>
                <Col xs={1}><img src="clipboard.png" style={{width: '30%', height: '50%'}} title={'Copy to clipboard'} onClick={() => setDoExport({count: doExport.count + 1, categoryName: categoryName, print: false})}/></Col>
                {/*<Col xs={1}><img src="print.png" style={{width: '30%', height: '50%'}} title={'Print'} onClick={() => setDoExport({count: doExport.count + 1, categoryName: categoryName, print: true})}/></Col>*/}
                <Col xs={1}><img src="logout.png" style={{width: '30%', height: '50%'}} title={'Logout'} onClick={logout}/></Col>
                <Col xs={1}><img src="settings.png" style={{width: '30%', height: '50%'}} title={'Admin'} onClick={navAdmin}/></Col>
                <Col xs={1}><img src="delete.png" style={{width: '30%', height: '50%'}} title={'Delete'} onClick={() => setDoDeleteSelected({count: doDeleteSelected.count + 1})}/></Col>
            </Row>            
            <CategoryComponent categoryChanged={categoryChanged} sessionId={sessionId} categoryName={setCategoryName}></CategoryComponent>
            <CreateTaskComponent createNewTask={createNewTask}></CreateTaskComponent>
            <TaskComponent ref={taskComponentRef} categoryId={selectedCategoryId} sessionId={sessionId} tasksPopulated={tasksPopulated} export={doExport} deleteSelected={doDeleteSelected}></TaskComponent>
        </div>
    )
}

export default MainViewComponent;