import React, { useState } from "react"
import { login } from "./DataInterface";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const LoginViewComponent = (props) => {

  const [credentials, setCredentials] = useState({username: null, password: null});
  const [showBadCredentials, setShowBadCredentials] = useState(false);
  
  function signIn(event) {

    let response = login(credentials.username, credentials.password);
    response.then(resp => {
      if (resp.status === 200) {
        props.setSessionId(resp.body.sessionId);
        props.setIsAdmin(resp.body.isAdmin);
      } else {
        setShowBadCredentials(true);
      }
    });   
  }

  function handleKeyPress(e) {
    if (e.charCode !== 13)
            return;
            
    signIn();
  }

  return (
    <div className="Auth-form-container">
      <div className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>User name</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Enter user name"
              value={credentials.username}
              onChange={(e) => setCredentials({...credentials, username: e.target.value})}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input id="txtPassword"
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              value={credentials.password}
              onChange={(e) => setCredentials({...credentials, password: e.target.value})}
              onKeyPress={(e) => handleKeyPress(e)}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button className="btn btn-primary" onClick={signIn}>
              Login
            </button>
          </div>
        </div>
      </div>

      <Modal show={showBadCredentials} onHide={() => setShowBadCredentials(false)}>
          <Modal.Header closeButton>
              <Modal.Title>Bad Credentials</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group className="mb-3">
                  <Form.Label>Invalid username or password</Form.Label>
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="primary" onClick={() => setShowBadCredentials(false)}>OK</Button>
          </Modal.Footer>
      </Modal>
    </div>
  )
}

export default LoginViewComponent;