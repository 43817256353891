import React from 'react';

export default class DeleteIconRenderer extends React.Component {
    constructor(props) {
      super(props);
      this.clickedHandler = this.clickedHandler.bind(this);
    }
    clickedHandler(event) {
      //this.props.clicked(this.props.node.data.task_id);
      this.props.node.data.deleteSelected = !this.props.node.data.deleteSelected;
      let colId = this.props.column.colId;
      this.props.node.setDataValue(colId, this.props.node.data.deleteSelected);
    }
    render() {
      if (this.props.node.data.deleteSelected) {
        return (
          <img src="delete-selected.png"
            onClick={this.clickedHandler}/>
        )
      } else {
      return (
          <img src="delete.png"
          onClick={this.clickedHandler}/>
      )
        }
    }
  }