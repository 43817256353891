import React, { useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { Container, Row, Col } from 'react-bootstrap';
import { formatDate } from './DataInterface.js'

import 'react-datepicker/dist/react-datepicker.css'

const CreateTaskComponent = (props) => {
    const [createdDate, setCreatedDate] = useState(new Date());
    const gridStyle = useMemo(() => ({ width: '100%' }), []);

    function handleKeyPress(e) {
        if (e.charCode !== 13)
            return;
        
        props.createNewTask(e.target.value, formatDate(createdDate));

        e.target.value = '';
    }

    return (
        <div className="ag-theme-alpine">
            <Container fluid>
                <Row>
                    <Col xs={1}><b>Date Created</b></Col>
                    <Col xs="auto"><DatePicker dateFormat='MM/dd/yy' selected={createdDate} onChange={(date) => setCreatedDate(date)}></DatePicker></Col>
                    <Col xs={1}><b>Task</b></Col>
                    <Col xs={6}><input type="text" style={gridStyle} onKeyPress={(e) => handleKeyPress(e)}/></Col>
                </Row>
            </Container>
        </div>
    )
}

export default CreateTaskComponent;