import React, { useEffect, useState } from "react"
import { deleteUser, getUsers, setPassword, updateUser, createUser } from "./DataInterface";
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

const AdminViewComponent = (props) => {

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [showSavedUser, setShowSavedUser] = useState(false);
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [showMismatchPasswords, setShowMismatchPasswords] = useState(false);
  const [passwordState, setPasswordState] = useState({});
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [newUserState, setNewUserState] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.sessionId || props.sessionId === '') {
      navigate('/');
      return;
    }
    
    loadUsers();
    
    }, [props.sessionId]);

  function loadUsers() {
    getUsers(props.sessionId).then(resp => {
      if (resp.status !== 200) {
        //navigate('/');
        return;
      }

      setUsers(resp.body.Items);
    });
  }

  function userChanged(e) {
    let userId = e.target.hash.substring(1);
    let user = users.find(e => e.user_name === userId);
    setSelectedUser(user);
  }

  function showDeleteUserModal() {
    setShowDeleteUser(true);
  }

  function handleDeleteUser() {
    setShowDeleteUser(false);
    deleteUser(props.sessionId, selectedUser.user_name).then((resp) =>
    {
      if (resp === 200) {
        loadUsers();
      } else {
        navigate('/');
      }
    })
  }

  function hideDeleteUserModal() {
    setShowDeleteUser(false);
  }

  function saveUser() {
    updateUser(props.sessionId, selectedUser.user_name, selectedUser.full_name, selectedUser.is_admin).then((resp) =>
    {
      if (resp === 200) {
        setShowSavedUser(true);
      } else {
        navigate('/');
      }
    })
  }

  function showNewUserModal() {
    setNewUserState({
      show: true,
      set: false,
      user_name: '',
      full_name: '',
      firstPassword: '',
      secondPassword: ''
    });
  }

  useEffect(() => {
    if (!newUserState.show)
      return;

    if (!newUserState.set) {
      setShowCreateUser(true);
      return;
    }

    if (newUserState.firstPassword !== newUserState.secondPassword) {
      setShowMismatchPasswords(true);
      setNewUserState({...newUserState, set: false});
      return;
    }

    createUser(props.sessionId, newUserState.user_name, newUserState.full_name, newUserState.firstPassword).then((resp) => {
      setNewUserState({...newUserState, show: false});
      setShowCreateUser(false);
      
      if (resp === 200) {
        loadUsers();
      } else {
        navigate('/');
      }
    });
  }, [newUserState]);

  function showChangePasswordModal() {
    setPasswordState({
      show: true,
      set: false,
      firstPassword: '',
      secondPassword: ''
    });
  }

  useEffect(() => {
    if (!passwordState.show)
      return;
    
    if (!passwordState.set) {
      setShowSetPassword(true);
      return;
    }

    if (passwordState.firstPassword !== passwordState.secondPassword) {
      setShowMismatchPasswords(true);
      setPasswordState({...passwordState, set: false});
      return;
    }

    setPassword(props.sessionId, selectedUser.user_name, passwordState.firstPassword).then((resp) =>
      {
        setPasswordState({...passwordState, show: false});
        setShowSetPassword(false);
      });
  }, [passwordState])

  return (
    <div class="container">
      <h1>User Administration</h1>
      <div class="row">
        <div class="col-sm-2">
          <ListGroup as="ul">
            {
              users.map((user) => 
                (<ListGroup.Item action href={"#" + user.user_name} onClick={userChanged}>{user.user_name}</ListGroup.Item>)
            )
            }
          </ListGroup>
          <Form.Group className="mb-1">
            <Button variant="primary" onClick={() => showNewUserModal()}>New User</Button>
          </Form.Group>
        </div>
        <div class="col-sm-2">
          <Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Full Name</Form.Label>
              <Form.Control as="input" value={selectedUser.full_name} onChange={(e) => setSelectedUser({...selectedUser, full_name: e.target.value})}/>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Is Admin?</Form.Label>
              <Form.Check as="input" checked={selectedUser.is_admin} onClick={(e) => setSelectedUser({...selectedUser, is_admin: e.target.checked})}/>
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Group className="mb-1">
              <Button variant="primary" onClick={() => saveUser()}>Save Changes</Button>
            </Form.Group>
            <Form.Group className="mb-1">
              <Button variant="secondary" onClick={() => showChangePasswordModal()}>Change Password</Button>
            </Form.Group>
            <Form.Group className="mb-1">
              <Button variant="danger" onClick={() => showDeleteUserModal()}>Delete</Button>
            </Form.Group>
          </Form.Group>
        </div>
      </div>

      <Modal show={showDeleteUser} onHide={() => hideDeleteUserModal()}>
          <Modal.Header closeButton>
              <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group className="mb-3">
                  <Form.Label>Are you sure you want to delete this user? This will delete all associated tasks and categories and cannot be undone.</Form.Label>
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={() => hideDeleteUserModal()}>No</Button>
              <Button variant="primary" onClick={() => handleDeleteUser()}>Yes</Button>
          </Modal.Footer>
      </Modal>

      <Modal show={showSavedUser} onHide={() => setShowSavedUser(false)}>
          <Modal.Header closeButton>
              <Modal.Title>Saved User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group className="mb-3">
                  <Form.Label>User saved successfully</Form.Label>
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="primary" onClick={() => setShowSavedUser(false)}>OK</Button>
          </Modal.Footer>
      </Modal>
      
      <Modal show={showSetPassword} onHide={() => setShowSetPassword(false)}>
          <Modal.Header closeButton>
              <Modal.Title>Set Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control as="input" type="password" autoFocus value={passwordState.firstPassword} onChange={(e) => setPasswordState({...passwordState, firstPassword: e.target.value})}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Re-enter Password</Form.Label>
              <Form.Control as="input" type="password" value={passwordState.secondPassword} onChange={(e) => setPasswordState({...passwordState, secondPassword: e.target.value})} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSetPassword(false)}>Cancel</Button>
            <Button variant="primary" onClick={() => setPasswordState({...passwordState, set: true})}>Set</Button>
          </Modal.Footer>
      </Modal>

      <Modal show={showCreateUser} onHide={() => setShowCreateUser(false)}>
          <Modal.Header closeButton>
              <Modal.Title>Create User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control as="input" autoFocus value={newUserState.user_name} onChange={(e) => setNewUserState({...newUserState, user_name: e.target.value})}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control as="input" value={newUserState.full_name} onChange={(e) => setNewUserState({...newUserState, full_name: e.target.value})}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control as="input" type="password" value={newUserState.firstPassword} onChange={(e) => setNewUserState({...newUserState, firstPassword: e.target.value})}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Re-enter Password</Form.Label>
              <Form.Control as="input" type="password" value={newUserState.secondPassword} onChange={(e) => setNewUserState({...newUserState, secondPassword: e.target.value})} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowCreateUser(false)}>Cancel</Button>
            <Button variant="primary" onClick={() => setNewUserState({...newUserState, set: true})}>Set</Button>
          </Modal.Footer>
      </Modal>

      <Modal show={showMismatchPasswords} onHide={() => setShowMismatchPasswords(false)}>
          <Modal.Header closeButton>
              <Modal.Title>Passwords mismatch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group className="mb-3">
                  <Form.Label>Passwords must match</Form.Label>
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="primary" onClick={() => setShowMismatchPasswords(false)}>OK</Button>
          </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AdminViewComponent;